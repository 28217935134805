import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, compact } from 'lodash';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
  InventoryTrimSummaryEntities,
} from 'client/data/models/inventory-core-configurations';
import { getParamsFromVehicle, CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { VisitorEntities } from 'client/data/models/visitor';
import { buildForSaleYearsPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { InventoryAggregateEntities } from 'client/data/models/inventory-aggregate';
import {
  ConsumerReviewsEntities,
  ConsumerReviewsModel,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { getUnifiedLeadFormDealerCountV2Path, UnifiedLeadFormModel } from 'client/data/models/unified-lead-form';
import { EditorialReviewEntities, FirstContentEntity } from 'client/data/models/editorial-review';
import { getLastWordPlural } from 'client/utils/plural';
import { generateSrpLinkMakeModel } from 'site-modules/shared/utils/srp-link-constructor';
import { IncentivesAd } from 'site-modules/shared/components/native-ad/incentives-ad/incentives-ad';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { getCustomCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { ScorecardEditorialReviewBody } from 'site-modules/core-page/components/scorecard-editorial-review/scorecard-editorial-review-body';
import { StickyToBottom } from 'site-modules/shared/components/sticky-to-bottom/sticky-to-bottom';
import { PhotoGallery } from 'site-modules/core-page/components/photo-gallery/photo-gallery';
import { PreprodPricingButton } from 'site-modules/core-page/components/preprod-pricing-button/preprod-pricing-button';
import {
  EditorialHighlightsLists,
  HIGHLIGHTS_NAME,
} from 'client/site-modules/shared/components/editorial-highlights-lists/editorial-highlights-lists';
import { GLOBAL_HEADER_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { SHORT_VIDEOS_MAP } from 'site-modules/core-page/constants/short-videos';
import { LOOKING_FOR_MODEL_MAP } from 'site-modules/core-page/constants/looking-for-model';
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { OtherYears } from 'site-modules/core-page/components/other-years/other-years';
import { BuildPriceButtonAtAGlanceAd } from 'site-modules/shared/components/native-ad/build-price-button-ad/build-price-button-at-a-glance-ad';
import { YearSubmodelControls } from 'site-modules/core-page/components/year-submodel-controls/year-submodel-controls';
import { CORE_GALLERY_SIZE, MENU_HASH } from 'site-modules/core-page/utils/constants';
import { PricingModule } from 'site-modules/core-page/components/pricing-module/pricing-module';
import { AnchorNavSticky } from 'site-modules/shared/components/anchor-nav-sticky/anchor-nav-sticky';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';
import { RangeAndCost } from 'site-modules/core-page/components/range-and-cost/range-and-cost';
import { VideoReviews } from 'site-modules/core-page/components/video-reviews/video-reviews';
import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { RatingScorecardEmbed } from 'site-modules/core-page/components/scorecard-editorial-review/rating-scorecard/rating-scorecard-embed';
import { EvInsights } from 'site-modules/core-page/components/ev-insights/ev-insights';
import { LookingForModel } from 'site-modules/core-page/components/new-core-intro/looking-for-model/looking-for-model';
import { CoreHighlights, CoreHighlightsUI } from 'site-modules/core-page/components/core-highlights/core-highlights';
import { TrimsCta } from 'site-modules/shared/components/core-page/trims/trims-cta';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { SponsoredCars } from 'site-modules/shared/components/core-page/sponsored-cars/sponsored-cars';
import { PricingAd } from 'site-modules/shared/components/native-ad/pricing-ad/pricing-ad';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { FindIncentivesBanner } from 'site-modules/core-page/components/find-incentives-banner/find-incentives-banner';

import { VehicleInfo } from './vehicle-info';

import './new-core-intro.scss';

export function NewCoreIntroUI({
  className,
  params,
  vehicle,
  editorialReview,
  consumerRatingsCount,
  prevYearConsumerRatingCount,
  recommendedTrim,
  firstContent,
  hasPreprodDealers,
  isPreprod,
  visitorLocation,
  isMobile,
  inventoryCount,
  hasLeadFormDealers,
  hasForSaleLink,
  isInternational,
  isConnect,
  isUnsoldBuildAndPriceAd,
  isCore6112Enabled,
  isCore6334Enabled,
}) {
  const { urlYear, noEditorialReview, isDealerlessOem, isElectric, isPluginHybrid } = params;
  const {
    year,
    make: { slug: makeSlug, name: makeName },
    model: { slug: modelSlug, name: modelName },
    submodels: { slug: submodelSlug },
  } = vehicle;

  const vehicleName = buildDisplayVehicle({
    makeModelSubmodelYear: vehicle,
    params,
    trimDuplicateSubmodel: true,
    updateChevroletMakeName: true,
    disableTrademarkCharacter: true,
  });

  const vehicleParams = getParamsFromVehicle(vehicle);
  const photoSize = isMobile ? CORE_GALLERY_SIZE.MOBILE.HERO : CORE_GALLERY_SIZE.DESKTOP.HERO;

  const hasCoreHighlights = !!(
    get(editorialReview, 'ratings.overall.rating') ||
    get(consumerRatingsCount, 'ratingAggregation.averageStars') ||
    get(prevYearConsumerRatingCount, 'ratingAggregation.averageStars')
  );
  const hasHighlights = get(editorialReview, 'cons.length') || get(editorialReview, 'pros.length');
  const hasPreprodCta = !!(hasPreprodDealers || inventoryCount?.total);

  const reviewHash = noEditorialReview ? MENU_HASH.CONSUMER_REVIEWS : MENU_HASH.REVIEWS;
  let menuItems = [
    {
      hash: isMobile ? MENU_HASH.TRIM_SUMMARY_MOBILE : MENU_HASH.TRIM_SUMMARY_DESKTOP,
      name: 'Pricing',
      customOffset: isMobile ? undefined : GLOBAL_HEADER_OFFSET,
    },
    { hash: reviewHash, name: 'Review' },
  ];

  if (!isConnect) {
    menuItems = compact([
      ...menuItems,
      { hash: MENU_HASH.COMPETITION, name: 'Compare' },
      { hash: MENU_HASH.FEATURES, name: 'Specs' },
    ]);
  }

  const shortVideos = get(SHORT_VIDEOS_MAP, [makeSlug, modelSlug, year]);
  const lookingForModel = get(LOOKING_FOR_MODEL_MAP, [makeSlug, modelSlug, year]);

  const showLeadForm = hasLeadFormDealers && !isDealerlessOem;
  const isReviewLink = !!get(editorialReview, 'reviewLink');
  const hasScorecardReview = isReviewLink && !!get(editorialReview, 'ratings.overall.rating');

  const pricingModule = !isPreprod && (
    <ScrollElement id="subnav-pricing" className="subnav-pricing px-md-1 py-2 px-0_5 bg-cool-gray-100 mx-md-0">
      <PricingModule params={params} vehicle={vehicle} isInternational={isInternational} />
    </ScrollElement>
  );

  const rangeAndCostModule = !isPreprod && !(isElectric || isPluginHybrid) && (
    <RangeAndCost params={params} vehicle={vehicle} className="mb-1_5" isMobile={isMobile} />
  );

  const evInsightsModule = !isPreprod && (isElectric || isPluginHybrid) && (
    <RatingScorecardEmbed childrenClassName="mb-1_5" isEmbed={hasScorecardReview}>
      <EvInsights
        vehicle={vehicle}
        isMobile={isMobile}
        isPluginHybrid={isPluginHybrid}
        isInternational={isInternational}
      />
    </RatingScorecardEmbed>
  );

  const filmstripModule = (
    <MinHeightWrapper componentType="SPONSORED_CARS" className="core-also-viewed-vehicles">
      <SponsoredCars trackLoading wrapperClassName="mt-2_5" showBottomDividingLine={false} showLargeArrows />
    </MinHeightWrapper>
  );

  const noForSaleText = (
    <Fragment>
      No {year} {makeName} {modelName} vehicles for sale in our network near{' '}
      <ZipWithDefaultActionHandling
        buttonClassName="text-primary-darker fw-normal text-underline py-0_25"
        showDropdownIcon={false}
      />
    </Fragment>
  );
  let srpLinkDescription = inventoryCount?.total ? 'Shop vehicle inventory at dealers near you' : noForSaleText;

  if (isInternational) {
    srpLinkDescription = `Shop ${year} ${makeName} ${modelName} vehicles for sale nationwide`;
  }

  const secondSrpLinkProps = {
    to: hasForSaleLink
      ? getCustomCoreUrl({ makeSlug, modelSlug, year }, 'for-sale/')
      : generateSrpLinkMakeModel(makeSlug, modelSlug),
    'data-tracking-id': hasForSaleLink ? 'floating_srp_link' : 'floating_used_srp_link',
    label: 'See All for Sale',
    creativeId: 'srp-link-under-site-incentives',
    className: 'px-1 px-md-1_5 py-0_75 py-md-1 bg-white rounded-8',
    descriptionClassName: 'mt-0_25 medium fst-italic',
    iconClassName: 'icon-price-tags2',
    iconContainerClassName: 'me-0_75 me-md-1_5 size-16 text-blue-50',
    iconStyles: isMobile ? { height: '40px', width: '40px' } : { height: '48px', width: '48px' },
    description: isMobile ? null : srpLinkDescription,
  };

  const coreHighlights = hasCoreHighlights && (
    <Fragment>
      {isPreprod ? (
        <CoreHighlightsUI
          className="px-1 pt-1 mt-1 mt-md-1_5"
          params={params}
          submodelSlug={submodelSlug}
          vehicle={vehicle}
          editorialReview={editorialReview}
          isMobile={isMobile}
          isPreprod
        />
      ) : (
        <CoreHighlights
          className="px-1 pt-1 mt-1 mt-md-1_5"
          params={params}
          submodelSlug={submodelSlug}
          vehicle={vehicle}
          editorialReview={editorialReview}
          isMobile={isMobile}
        />
      )}
    </Fragment>
  );

  const showInGrayContent = !isMobile && !isPreprod;

  const highlightsListFallback = (
    <EditorialHighlightsLists
      editorialReview={editorialReview}
      firstContent={firstContent}
      params={params}
      className={classnames({
        'mb-2': !showInGrayContent,
        'pb-0_5': showInGrayContent,
      })}
      isPreprod={isPreprod}
      sectionsToRender={hasHighlights ? [HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS] : [HIGHLIGHTS_NAME.WHAT_TO_EXPECT]}
    />
  );

  const introOtherYearsPills = (
    <Fragment>
      {!isPreprod && (
        <Fragment>
          {isConnect || !hasForSaleLink ? (
            <ShoppingLink {...secondSrpLinkProps} />
          ) : (
            <Experiment name="core-6275-srp-ctas" showDefault>
              <Recipe name="ctrl" isDefault>
                <ShoppingLink {...secondSrpLinkProps} />
              </Recipe>
              <Recipe name="chal">
                <ShoppingLink
                  {...secondSrpLinkProps}
                  label={`New ${makeName} ${getLastWordPlural(modelName)} for Sale`}
                />
              </Recipe>
            </Experiment>
          )}
          {isMobile && !inventoryCount?.total && !isInternational && (
            <div className="medium fst-italic mt-0_75">{noForSaleText}</div>
          )}
        </Fragment>
      )}
      <OtherYears
        heading={isPreprod ? undefined : 'See other years'}
        headingClassName="mb-0_5 mt-2"
        vehicle={vehicle}
        renderEmptyHidden
        isMobile={isMobile}
        hasSpeculationRule
      />
    </Fragment>
  );

  const videoReviewsProps = {
    className: 'mb-3_5',
    shortVideos,
    vehicleName: `${makeName} ${modelName}`,
    creativeId: 'video-carousel',
    isMobile,
  };

  const shortVideosModule = !!shortVideos && (
    <VideoReviews {...videoReviewsProps} className="mt-2 mb-2" isCore6718Enabled />
  );

  const editorialReviewProps = {
    params,
    vehicle,
    hasRouteYearParam: !!urlYear,
    isPreprod,
    showHighlights: true,
    hasPreprodCta,
    embeddedModules: { pricingModule, rangeAndCostModule, evInsightsModule, shortVideosModule, filmstripModule },
    isExpandedScorecard: true,
    hasCoreHighlights,
    hasTrimsLink: !isPreprod,
    isCore6112Enabled,
  };

  return (
    <Container className={classnames('new-core-intro', className)}>
      <div className="d-flex flex-wrap align-items-start mb-md-0_5">
        <h1 className="intro-title heading-2 text-white mb-0_5 me-md-1_5">{vehicleName}</h1>
        <LookingForModel model={lookingForModel} />
      </div>
      {(!isConnect || !isMobile) && (
        <Row
          className={classnames('intro-header align-items-end justify-content-between mb-0_5 mb-md-1_5', {
            'border-0': isPreprod && isMobile,
          })}
        >
          <Col xs={12} md={6} lg={7} className="px-0_5">
            <VehicleInfo
              vehicle={vehicle}
              firstContent={firstContent}
              isPreprod={isPreprod}
              isMobile={isMobile}
              className="mb-0_75 my-md-0 text-white"
            />
          </Col>
          {/* Empty Col can be rendered for critical CSS (CLS) */}
          <Col xs={12} md={6} lg={5} className="px-0_5">
            {!isPreprod && (
              <AnchorNavSticky
                navList={menuItems}
                title={null}
                stickyTitle={vehicleName}
                hasSeparator={false}
                containerClassName={null}
                titleClassName="heading-4 py-0_75 hidden-sm-down"
                className="intro-navigation"
                stickyClassName="justify-content-between pt-1 pt-md-0"
                listClassName={classnames('d-flex align-items-end mx-0 size-16', {
                  'justify-content-between': !isConnect,
                  'justify-content-md-end': isConnect,
                })}
                stickyListClassName={classnames('d-flex align-items-end mx-0 medium', {
                  'justify-content-between': !isConnect,
                  'justify-content-md-end': isConnect,
                })}
                anchorNavContentClassName="w-100"
                stickyAnchorNavContentClassName="col-12 col-md-6 col-lg-5 align-self-end px-0 ps-md-0_5"
                navWrapperClassName={null}
                itemClassName="px-0_75 px-xl-1"
                stickyItemClassName="px-0_75 px-xl-1"
                linkClassName="pt-md-0 text-white px-md-0_25 py-0_5"
                stickyLinkClassName="pt-md-0 py-0_5"
                linkActiveClassName="link-active fw-bold"
                stickyLinkActiveClassName="link-active text-primary-darker"
                creativeId="edm-entry-sub-nav"
                isMobile={isMobile}
                linkTrackingId="view_content_non_docked"
                stickyLinkTrackingId="view_content_docked"
              />
            )}
          </Col>
        </Row>
      )}

      <div className="intro-content">
        <ScrollElement className="intro-gallery overview-container" id="subnav-overview">
          <PhotoGallery
            params={params}
            isMobile={isMobile}
            vehicle={vehicle}
            editorialReview={editorialReview}
            isPreprod={isPreprod}
            photoSize={photoSize}
            isConnect={isConnect}
          />

          {showInGrayContent ? (
            <div className="bg-cool-gray-90 rounded-12 px-1 py-1 mt-1_5 mt-md-1_5">
              {hasCoreHighlights && (
                <CoreHighlights
                  className={classnames({ 'px-0 px-lg-0_25 px-xl-0': !get(editorialReview, 'ratings.overall.text') })}
                  params={params}
                  submodelSlug={submodelSlug}
                  vehicle={vehicle}
                  editorialReview={editorialReview}
                  isMobile={isMobile}
                />
              )}
              {!hasCoreHighlights && highlightsListFallback}
              <IncentivesAd
                wrapperClass={classnames('mb-1', {
                  'mt-1_5 mt-md-1_5': !hasCoreHighlights,
                })}
                cardWrapper="rounded-12 bg-cool-gray-90"
                horizontalLayout
                showOnlySiteServed
              />
              {introOtherYearsPills}
            </div>
          ) : (
            <Fragment>{!isMobile && coreHighlights}</Fragment>
          )}
        </ScrollElement>

        {isMobile && coreHighlights}

        <ScrollElement id="subnav-trim-summary-desktop" className="intro-trims d-flex flex-column">
          <StickyToBottom enabled={!isMobile} offsetSelector=".intro-header" additionalOffset={16}>
            {({ onHeightUpdate }) => (
              <Fragment>
                {isPreprod ? (
                  <div className="trim-summary-section rounded-12 px-0_5 pb-1 pt-1 mt-1 mt-md-1_5 mt-lg-0 bg-cool-gray-90">
                    <ScrollElement
                      id="subnav-trim-summary-mobile"
                      tag={Card}
                      className="intro-trims-content px-1 px-lg-0_5 px-xl-1 py-1 m-lg-0 border-0 rounded-8"
                      data-tracking-parent="edm-entry-model-summary"
                    >
                      {((!(hasHighlights && hasPreprodCta) && !isMobile) || (isMobile && !hasCoreHighlights)) &&
                        highlightsListFallback}
                      <PreprodPricingButton
                        hasSrpLink={!!inventoryCount?.total}
                        vehicle={vehicle}
                        isMobile={isMobile}
                        copyClassName="mb-1 text-gray-darker"
                        btnClassName="w-100 py-0_75 px-1"
                      />
                      {isMobile && (
                        <Fragment>
                          <hr className="mt-1 mb-0_75 w-100" style={{ borderColor: '#64748B' }} />
                          <IncentivesAd
                            wrapperClass="px-lg-0"
                            mobile={isMobile}
                            placeholderComponent={FindIncentivesBanner}
                            placeholderComponentProps={{
                              makeSlug,
                              makeName,
                              modelSlug,
                              modelName,
                              year,
                              isPreprod,
                            }}
                            showOnlySiteServed
                          />
                        </Fragment>
                      )}
                    </ScrollElement>
                  </div>
                ) : (
                  <div className="trim-summary-section modular rounded-12 bg-cool-gray-90 pb-1 pt-1 px-1 mt-1 mt-md-1_5 mt-lg-0">
                    {isMobile && !hasCoreHighlights && highlightsListFallback}
                    <h2 className="heading-3 mb-1_5">Pricing</h2>
                    <ScrollElement
                      id="subnav-trim-summary-mobile"
                      tag={Card}
                      className="intro-trims-content px-1 px-lg-0_5 px-xl-1 py-1 m-lg-0 border-0 rounded-8"
                      data-tracking-parent="edm-entry-model-summary"
                    >
                      <YearSubmodelControls params={params} vehicle={vehicle} isCore6334Enabled={isCore6334Enabled} />
                      <TrimsCta
                        params={vehicleParams}
                        vehicle={vehicle}
                        recommendedTrimSlug={recommendedTrim?.trim}
                        visitorLocation={visitorLocation}
                        showLeadForm={showLeadForm}
                        isElectric={isElectric}
                        isDealerlessOem={params.isDealerlessOem}
                        isMobile={isMobile}
                        isCore6334Enabled={isCore6334Enabled}
                        isCore6776Test
                      />
                      {isMobile && (
                        <Experiment name="ads-10773" showDefault>
                          <Recipe name="ctrl" isDefault>
                            <hr className="w-100 mt-2 mb-0_5" />
                            <IncentivesAd mobile showOnlySiteServed plainLayout />
                          </Recipe>
                          <Recipe name="chal1">
                            <hr className="w-100 mt-2 mb-0_5 is-ads-10773-chal1" />
                            <IncentivesAd mobile showOnlySiteServed plainLayout ads10773Recipe="chal1" />
                          </Recipe>
                          <Recipe name="chal2">
                            <IncentivesAd mobile showOnlySiteServed plainLayout ads10773Recipe="chal2" />
                          </Recipe>
                          <Recipe name="chal3">
                            <IncentivesAd mobile showOnlySiteServed plainLayout ads10773Recipe="chal3" />
                          </Recipe>
                        </Experiment>
                      )}
                    </ScrollElement>
                  </div>
                )}
                {isMobile && isConnect && (
                  <EditorialHighlightsLists
                    editorialReview={editorialReview}
                    firstContent={firstContent}
                    params={params}
                    className="mt-1_5 mb-2"
                    sectionsToRender={[HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS, HIGHLIGHTS_NAME.WHAT_TO_EXPECT]}
                    isPreprod={isPreprod}
                  />
                )}
                {!isMobile && (
                  <BuildPriceButtonAtAGlanceAd
                    mobile={false}
                    position={'1'}
                    wrapperClass="mt-1"
                    slotRenderEndListener={onHeightUpdate}
                  />
                )}
              </Fragment>
            )}
          </StickyToBottom>
          {!isMobile && isUnsoldBuildAndPriceAd && (
            <MrectNative
              position={'0'}
              wiredBreakpoints={{ lg: true, xl: true }}
              useMinHeight
              wrapperClass="mt-1_5 hidden-md-down"
            />
          )}
        </ScrollElement>

        {!showInGrayContent && (
          <div className="intro-other-years-pills mt-2 mt-md-1 mt-lg-0 mb-2 mb-lg-0">
            <div
              className={classnames({
                'with-srp-link-container bg-cool-gray-90 rounded-12 mx-0 p-1': !isPreprod,
              })}
            >
              {introOtherYearsPills}
            </div>
            {!isMobile && isPreprod && <IncentivesAd wrapperClass="mt-2" horizontalLayout showOnlySiteServed />}
          </div>
        )}

        {!!(editorialReview || shortVideos || isPreprod) && (
          <ScrollElement id="subnav-reviews" className="intro-editorial">
            {!editorialReview && <VideoReviews {...videoReviewsProps} />}
            {!!editorialReview && (
              <Fragment>
                {!isConnect && !isPreprod ? (
                  <Experiment name="core-6718-trust" showDefault>
                    <Recipe name="ctrl" isDefault>
                      <VideoReviews {...videoReviewsProps} />
                      <ScorecardEditorialReviewBody {...editorialReviewProps} />
                    </Recipe>
                    <Recipe name="chal">
                      <ScorecardEditorialReviewBody {...editorialReviewProps} isCore6718Enabled />
                    </Recipe>
                  </Experiment>
                ) : (
                  <Fragment>
                    <VideoReviews {...videoReviewsProps} />
                    <ScorecardEditorialReviewBody {...editorialReviewProps} />
                  </Fragment>
                )}
              </Fragment>
            )}
            {isPreprod && (
              <Experiment name="ads-10779" showDefault>
                <Recipe name="ctrl" isDefault>
                  <PricingAd wrapperClass="mt-1_5 mb-1_5" mobile={isMobile} showHorizontalLayout showOnlySiteServed />
                </Recipe>
                <Recipe name="chal1" />
                <Recipe name="chal2" />
                <Recipe name="chal3" />
              </Experiment>
            )}
          </ScrollElement>
        )}

        {!isReviewLink && rangeAndCostModule}
        {!hasScorecardReview && (
          <div className="intro-other">
            {pricingModule}
            {filmstripModule}
          </div>
        )}
      </div>
    </Container>
  );
}

NewCoreIntroUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  className: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  isMobile: PropTypes.bool,
  editorialReview: EditorialReviewEntities.EditorialReview,
  consumerRatingsCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  prevYearConsumerRatingCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  firstContent: FirstContentEntity,
  recommendedTrim: InventoryTrimSummaryEntities.TrimSummary,
  inventoryCount: InventoryAggregateEntities.InventoryCountByType,
  hasLeadFormDealers: PropTypes.bool,
  hasPreprodDealers: PropTypes.bool,
  hasForSaleLink: PropTypes.bool,
  isPreprod: PropTypes.bool,
  isInternational: PropTypes.bool,
  isConnect: PropTypes.bool,
  isUnsoldBuildAndPriceAd: PropTypes.bool,
  isCore6112Enabled: PropTypes.bool,
  isCore6334Enabled: PropTypes.bool,
};

NewCoreIntroUI.defaultProps = {
  className: 'pb-3_5',
  visitorLocation: {},
  isMobile: false,
  editorialReview: null,
  consumerRatingsCount: null,
  prevYearConsumerRatingCount: null,
  vehicle: null,
  firstContent: null,
  recommendedTrim: null,
  inventoryCount: null,
  hasLeadFormDealers: false,
  hasPreprodDealers: false,
  hasForSaleLink: false,
  isPreprod: false,
  isInternational: false,
  isConnect: false,
  isUnsoldBuildAndPriceAd: false,
  isCore6112Enabled: false,
  isCore6334Enabled: false,
};

export const NewCoreIntro = connectToModel(NewCoreIntroUI, {
  hasLeadFormDealers: bindToPath(
    ({ vehicle, isPreprod }) => !isPreprod && buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    trims => !!get(trims, '[0].hasLeadFormDealers')
  ),
  recommendedTrim: bindToPath(
    ({ vehicle, isPreprod }) => !isPreprod && buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    (trims, { recommendedTrimSlug }) => trims?.find(({ trim }) => trim === recommendedTrimSlug)
  ),
  hasPreprodDealers: bindToPath(
    ({ vehicle, isPreprod }) =>
      isPreprod &&
      getUnifiedLeadFormDealerCountV2Path({
        makeSlug: vehicle.make.slug,
        modelSlug: vehicle.model.slug,
        strategy: LEAD_FORM_CONFIGS.QUICK_QUOTES_CPL.strategy,
      }),
    UnifiedLeadFormModel,
    dealerCount => !!dealerCount
  ),
  hasForSaleLink: bindToPath(
    ({ vehicle }) => buildForSaleYearsPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
    VehicleModel,
    (forSaleYears, { vehicle: { year } }) => forSaleYears.includes(parseInt(year, 10))
  ),
  consumerRatingsCount: bindToPath(
    ({ params, isPreprod }) => !isPreprod && ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath(params),
    ConsumerReviewsModel
  ),
  prevYearConsumerRatingCount: bindToPath(
    ({ params, isPreprod }) =>
      !isPreprod && ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({ ...params, year: params.year - 1 }),
    ConsumerReviewsModel
  ),
  isUnsoldBuildAndPriceAd: bindToPath('isUnsoldBuildAndPriceAd', NativeAdsCreativeConfigModel),
});
